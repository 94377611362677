// 菜单7案例
<template>
  <div class="main">
    <div class="top">
      <div class="top-title">CASE</div>
      <div class="top-text">
        Here are the real cases of the homes of the customers we have served
        before. You can use them as references to choose products.
      </div>
    </div>
    <div class="content">
      <div class="second-list">
        <div
          class="second-item"
          v-for="(v, i) in ctIdList"
          :key="i"
          :class="{ 'second-item-active': ctId == v.ctId }"
          @click="selectItem(v.ctId)"
        >
          <img
            :src="`${ossPrefix}${v.icon}`"
            class="child-icon"
            v-if="i != 0"
          />
          {{ v.name }}
        </div>
      </div>
      <div class="case-list" v-if="caseList.length > 0">
        <img
          v-for="(v, i) in caseList"
          :key="i"
          @click="toPreviewImg(i)"
          :src="`${ossPrefix}${v.fileCover}`"
          class="case-item-pic"
        />
        <!-- <div class="more" @click="addCurrentPage()" v-if="hasMoreItems">
          <div>View more</div>
          <i class="el-icon-bottom more-icon"></i>
        </div> -->
      </div>
      <div class="no-list" v-else>
        <img
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/prod_icon_default@2x.png"
          class="no-list-pic"
        />
      </div>
      <!-- <div class="more" v-if="!hasMoreItems && caseList.length > 0">
        <div>There's nothing more left</div>
      </div> -->
    </div>
    <image-preview-modal
      v-if="isModalVisible"
      :images="imgsList"
      :currentIndex="currentIdx"
      :isVisible="isModalVisible"
      @close="closeModal"
    />
  </div>
</template>
<script>
import {
  usVisitLogInsert,
  usCaseTypeList,
  usCaseFileList,
} from "@/api/home.js";
import ImagePreviewModal from "@/components/ImagePreviewModal.vue";
export default {
  name: "Service",
  components: { ImagePreviewModal },
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
      currentPage: 1,
      pageSize: 12,
      totalPage: 0,
      ctId: "",
      ctIdList: [],
      caseList: [],
      isModalVisible: false,
      currentIdx: 0,
      imgsList: [],
    };
  },
  activated() {
    this.ctId = "";
    this.getCaseTypeList();
    this.addVisitLogInsert();
  },
  methods: {
    toPreviewImg(index) {
      let list = [];
      this.caseList.forEach((v) => {
        list.push(`${this.ossPrefix}${v.fileCover}`);
      });
      this.imgsList = list;
      this.currentIdx = index;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    selectItem(id) {
      this.ctId = id;
      this.getData();
    },
    // 向后端请求分类列表数据
    getCaseTypeList() {
      usCaseTypeList().then((res) => {
        if (res.code == 1) {
          const val1 = {
            ctId: "",
            name: "ALL",
          };
          const cate = res.data.dataList;
          cate.unshift(val1);
          this.ctIdList = cate;
          this.getData();
        } else {
          const val1 = {
            ctId: "",
            name: "ALL",
          };
          const cate = [];
          cate.unshift(val1);
          this.ctIdList = cate;
          this.getData();
          this.$message.error(res.message);
        }
      });
    },
    // 向后端请求全部商品或分类商品数据
    getData() {
      // 如果分类列表为空则请求全部商品数据，否则请求分类商品数据
      usCaseFileList(this.ctId).then((res) => {
        // console.log(res);
        if (res.code == 1) {
          this.caseList = [...res.data.dataList];
        } else {
          this.caseList = [];
          this.$message.error(res.message);
        }
      });
    },
    addVisitLogInsert() {
      let website = window.location.href;
      let websiteTitle = "Case";
      usVisitLogInsert(website, websiteTitle, 0).then(() => {});
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  box-sizing: border-box;
}
.top {
  width: 1920px;
  height: 260px;
  margin: 0 0 58px;
  background-image: url("https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/os_icon_case@2x.png");
  background-size: cover;
  position: relative;
}
.top-title {
  font-weight: bold;
  font-size: 34px;
  color: #fff;
  position: absolute;
  top: 74px;
  left: 405px;
}
.top-text {
  width: 1100px;
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 122px;
  left: 405px;
}
.content {
  width: 1110px;
  margin: 0 auto 21px;
  font-size: 16px;
  color: #262626;
}
.second-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px;
  box-sizing: border-box;
  white-space: nowrap;
}
.second-item {
  display: flex;
  align-items: center;
  margin: 0 36px 25px 0;
  font-size: 24px;
  cursor: pointer;
}
.second-item-active {
  font-weight: bold;
  color: #caa68a;
}
.case-list {
  width: 100%;
  margin: 20px auto 35px;
  display: flex;
  flex-wrap: wrap;
}

.case-item {
  width: 380px;
  height: 380px;
  margin: 25px 30px 25px 0;
  border-radius: 20px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.case-list :nth-child(3n) {
  margin-right: 0;
}
.case-item-pic {
  width: 355px;
  height: 355px;
  margin: 0 22.5px 35px 0;
  cursor: pointer;
}
.more {
  padding: 0;
  text-align: center;
  margin: 0 auto 179px;
  color: #74c9c6;
  font-size: 30px;
  cursor: pointer;
}
.more-icon {
  margin: 0 auto;
  color: #74c9c6;
  font-size: 30px;
  cursor: pointer;
}
.no-list {
  width: 100%;
  height: 500px;
  margin: 0 auto 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: #cccccc;
  font-size: 26px;
}
.no-list-pic {
  width: 466px;
  height: 466px;
}
.child-icon {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}
</style>
